import {Component, Input, OnInit} from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.css']
})
export class FooterLinksComponent implements OnInit {

  @Input() color = 'white';
  @Input() title: any;
  @Input() content: any;

  constructor() { }

  ngOnInit() {
  }
  jumpTo(href) {
    //let's jump is it is a jumper link
    if (href != 'login') {
      $('html, body').stop().animate({
        scrollTop: $(href).offset().top
      }, 1500);
    }
  }
}
