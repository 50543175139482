import {Component, Input, OnInit, ViewChild} from '@angular/core';
import $ from 'jquery';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from "../../../../../app.component";
import {EventEmitterService} from "../../../../../event-emitter.service";
import {AuthService} from "../../../../../services/auth.service";


@Component({
  selector: 'app-header-style1',
  templateUrl: './header-style1.component.html',
  styleUrls: ['./header-style1.component.css']
})
export class HeaderStyle1Component implements OnInit {
  email: string = '';
  password: string = '';
  @ViewChild('modal', {static: false}) modal: any;

  @Input() logoImg: string;

  @Input() navItemList: any[];

  @Input() className: string;

  @Input() styledLogo = true;

  constructor(public translate: TranslateService, public serTrans: EventEmitterService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {
  }


  jumpTo(href) {
    //let's jump is it is a jumper link
    if (href != 'login') {
      $('html, body').stop().animate({
        scrollTop: $(href).offset().top
      }, 1500);
    }
  }

  translateLa(lang) {
    this.serTrans.useLanguage(lang);
  }

  signUp() {
    //this.authS.SignUp(this.email, this.password);
  }
}
