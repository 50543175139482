// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCiHM7-yyKr1RNHoBG6Xv3RSqgJLV4PzbQ",
    authDomain: "afro-79c08.firebaseapp.com",
    databaseURL: "https://afro-79c08.firebaseio.com",
    projectId: "afro-79c08",
    storageBucket: "afro-79c08.appspot.com",
    messagingSenderId: "29395536085",
    appId: "1:29395536085:web:bab488593c26b817adfa5c",
    measurementId: "G-B13YPF4LMR"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
