import { Component } from '@angular/core';
import { PluginsService } from './sofbox/plugins.service';
import { Router, NavigationEnd} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {EventEmitterService} from "./event-emitter.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private plugins: PluginsService, private router: Router, public translate: TranslateService,
              private eventEmitterService: EventEmitterService
  ) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

  }

  title = 'DPRM';

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }



}
