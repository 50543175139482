import { Component, OnInit } from '@angular/core';
import { ContactFormService } from './contact-form.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  public loading = false;
  constructor(private contactService : ContactFormService) { }

  mailData = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };

  statusMail = false;
  buttonDis = true;

  ngOnInit() {

  }

  onSubmit (e) {
    this.loading = true;
    e.preventDefault();
    this.contactService.sendMail(this.mailData).subscribe((data) => {
      this.statusMail = true;
      console.log(data);
          this.loading = false;
      if(this.mailData.message && this.mailData.name){

        Swal.fire(

            'Good job!',
            'You have send!',
            'success'
        )
      }else{

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'pleace enter your name and message!'
        })
        this.loading = false;
      }

      this.buttonDis = true;
    },
        err=>{
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          })
        });
  }

  resolved(captchaResponse: string) {
    console.log(this.buttonDis);
    this.buttonDis = false;
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
}
