import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;

  constructor( public translate: TranslateService) { }

  onFirstComponentButtonClick() {
    this.invokeFirstComponentFunction.emit();
  }

  public useLanguage(language: string) {
    this.translate.use(language);
    console.log('change ok: ', language)
  }

}
