import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SofboxModule } from './sofbox/sofbox.module';
import { LandingPage8Module } from './landing-page8/landing-page8.module';
import { LandingPage1Module } from './landing-page1/landing-page1.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import {ContactFormService} from './sofbox/components/contact-form/contact-form.service';
import {AngularFireModule} from "@angular/fire";

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {AngularFirestore} from "@angular/fire/firestore";
import { NgxLoadingModule } from 'ngx-loading';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {EventEmitterService} from "./event-emitter.service";
import {AngularFireAuth} from "@angular/fire/auth";
import {environment} from 'src/environments/environment.prod';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}




@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        NgxLoadingModule.forRoot({}),
        AppRoutingModule, TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SofboxModule,
        LandingPage8Module,
        LandingPage1Module,
        RecaptchaModule,
        SweetAlert2Module.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        HttpClientModule
    ],
    providers: [
        ContactFormService,
        AngularFirestore,
        EventEmitterService,
        AngularFireAuth

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
