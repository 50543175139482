export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyALXIskhtRAk1665lJiZ-UfVNze20Q526Q",
    authDomain: "medecin-patient-17c44.firebaseapp.com",
    databaseURL: "https://medecin-patient-17c44.firebaseio.com",
    projectId: "medecin-patient-17c44",
    storageBucket: "medecin-patient-17c44.appspot.com",
    messagingSenderId: "142069350215",
    appId: "1:142069350215:web:1ff83da134af3d7bbf5662",
    measurementId: "G-4D8JRS3EC3"
  }
};

